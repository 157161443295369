@import '../abstracts/variables';
@import '../abstracts/media';

@import '~@angular/material/theming';
@include mat-core();

$user-palette: (
  100: $primary100,
  200: $primary200,
  300: $primary300,
  A100: $accent100,
  A200: $accent200,
  contrast: (
    50: #000000,
    100: #000000,
    200: #ffffff,
    300: #ffffff,
    400: #ffffff,
  ),
);

$lam-user-primary: mat-palette($user-palette, 100, 200, 300);
$lam-user-accent: mat-palette($user-palette, A100, A200);
$lam-user-warn: mat-palette($mat-red);
$lam-user-theme: mat-light-theme($lam-user-primary, $lam-user-accent, $lam-user-warn);

$lam-typography: mat-typography-config(
  $font-family: 'Montserrat, sans-serif',
  $body-1: mat-typography-level(1rem, 1.2, 400),
  $body-2: mat-typography-level(1.4em, 1.2, 500),
  $display-4: mat-typography-level(11.2rem, 1.2, 500),
  $display-3: mat-typography-level(5.6rem, 1.2, 500),
  $display-2: mat-typography-level(4.5rem, 1.2, 500),
  $display-1: mat-typography-level(3.4rem, 1.2, 500),
  $headline: mat-typography-level(2.4rem, 1.2, 500),
  $title: mat-typography-level(2rem, 1.2, 500),
  $subheading-2: mat-typography-level(1.6rem, 1.2, 400),
  $subheading-1: mat-typography-level(1.5rem, 1.2, 400),
  $caption: mat-typography-level(1.2rem, 1.2, 400),
);

@include mat-core($lam-typography);

$lam-typography-md: mat-typography-config(
  $font-family: 'Montserrat, sans-serif',
  $body-1: mat-typography-level(1.6rem, 1.2, 400),
  $body-2: mat-typography-level(2.24rem, 1.2, 500),
  $display-4: mat-typography-level(17.92rem, 1.2, 500),
  $display-3: mat-typography-level(8.96rem, 1.2, 500),
  $display-2: mat-typography-level(7.2rem, 1.2, 500),
  $display-1: mat-typography-level(5.54rem, 1.2, 500),
  $headline: mat-typography-level(3.84rem, 1.2, 500),
  $title: mat-typography-level(3.2rem, 1.2, 500),
  $subheading-2: mat-typography-level(2.56rem, 1.2, 400),
  $subheading-1: mat-typography-level(2.4rem, 1.2, 400),
  $caption: mat-typography-level(1.92rem, 1.2, 400),
  $button: mat-typography-level(1.92rem, 1.2, 400),
);

@include media-breakpoint-up(sm) {
  @include mat-core($lam-typography-md);
}

@include angular-material-theme($lam-user-theme);

.root {
  margin: 0 auto;
  max-width: $max-width;
  min-height: 100vh;
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  padding: 0 $default-padding;
}

.divider {
  width: 100%;
  height: 1px;
  border: 1px solid rgb(255, 255, 255);

  margin: 1rem 0;
}

.snackbar {
  color: #fff;

  &--error {
    background-color: #d32f2f;
  }

  &--success {
    background-color: #2e7d32;
  }
}
